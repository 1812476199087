import http from "./_http";

class CountryServices {
  static async getCountryCode() {
    const response = await http.get("/api/v1/hotline/code");
    return response.data.code || "global";
  }
}

export default CountryServices;
