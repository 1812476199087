import React, { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { Redirect } from "react-router";
import styled from "styled-components";
import ContentSwitcher from "../../components/content_switcher";
import AvailableOnAndroidContext from "../../components/context/available_on_android_context";
import CountryContext from "../../components/context/country_context";
import Loading from "../../components/loading";
import CountryServices from "../../services/country_services";
import Body from "./body";
import Footer from "./footer";
import Header from "./header";

const Main = () => {
  const [show, setShow] = useState(isAndroid);
  const [countryCode, setCountryCode] = useState();

  useEffect(() => {
    (async () => {
      const code = await CountryServices.getCountryCode();
      setCountryCode(code);
    })();
  }, []);

  return (
    <>
      {!countryCode ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <CountryContext.Provider value={countryCode}>
          <AvailableOnAndroidContext.Provider value={{ show, setShow }}>
            <div className="app">
              <Header
                renderSwitcher={countryCode === "KH" && <ContentSwitcher />}
              />
              <Body />
              <Footer />
              {countryCode === "KH" && <Redirect to="/km" />}
            </div>
          </AvailableOnAndroidContext.Provider>
        </CountryContext.Provider>
      )}
    </>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 20% 0px;
  justify-content: center;
`;

export default Main;
