import React, { useContext, useCallback } from "react";
import { Row } from "reactstrap";
import styled from "styled-components";
import StringsContext from "./context/strings_context";

const LanguageSwitcher = () => {
  const { language, setLanguage } = useContext(StringsContext);
  const setEnglish = useCallback(() => {
    setLanguage("en");
  }, [setLanguage]);
  const setKhmer = useCallback(() => {
    setLanguage("km");
  }, [setLanguage]);

  return (
    <Center>
      <Lang onClick={setEnglish} selected={language === "en"}>
        EN
      </Lang>
      <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
      <Lang onClick={setKhmer} selected={language === "km"}>
        ខ្មែរ
      </Lang>
    </Center>
  );
};

const Center = styled(Row)`
  align-items: center;
  color: "#263238";
  margin: 0;
`;

const Lang = styled.div`
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  color: ${({ selected }) => (selected ? "#455A64" : "#90A4AE")};
`;

export default LanguageSwitcher;
