import React from "react";
import StringsContext from "../context/strings_context";

const withStrings = Component => props => {
  Component.displayName = `withStrings(${Component.name |
    Component.displayName})`;

  return (
    <StringsContext.Consumer>
      {({ strings, setLanguage }) => (
        <Component {...props} strings={strings} setLanguage={setLanguage} />
      )}
    </StringsContext.Consumer>
  );
};

export default withStrings;
