import { AppFooter } from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import LanguageSwitcher from "../../components/language_switcher";
import withStrings from "../../components/hoc/withStrings";
import { MobileView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

const Footer = ({ strings }) => {
    const [isHome, setIsHome] = useState(true);
    const history = useHistory();
    const { listen } = useHistory();
    useEffect(() => {
        return listen(location => {
            if (location.pathname === '/km' || location.pathname === '/') {
                setIsHome(true);
            } else {
                setIsHome(false);
            }
        });
    }, [])
    const back = useCallback(() => {
        history.goBack();
    }, []);
    return (
        <AppFooter fixed>
            <MainContainer>
                <MobileView style={{ minWidth: 35 }}>
                    {!isHome && <div onClick={back}>{strings.label("action_go_back")}</div>}
                </MobileView>
                <div>
                    <AboutUsContainer href="#/about-us">{strings.label("label_about_us")}</AboutUsContainer></div>
                <div><LanguageSwitcher /></div>
            </MainContainer>
        </AppFooter>
    )
};

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const AboutUsContainer = styled.a`
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    color: #455A64;
    &:visited, &:hover{
        text-decoration: none !important;
    }
`

export default withStrings(Footer);
