import React, { useContext } from "react";
import { Row } from "reactstrap";
import styled from "styled-components";
import StringsContext from "../../../components/context/strings_context";

const WHOAdvice = () => {
  const { strings } = useContext(StringsContext);
  return (
    <>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public"
          target="_blank"
        >
          {strings.label("label_protect_yourself")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/technical-guidance"
          target="_blank"
        >
          {strings.label("label_technical_guidance")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses"
          target="_blank"
        >
          {strings.label("label_qa")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/travel-advice"
          target="_blank"
        >
          {strings.label("label_travel_advice")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/situation-reports/"
          target="_blank"
        >
          {strings.label("label_situation_reports")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/media-resources/press-briefings"
          target="_blank"
        >
          {strings.label("label_media_resources")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/global-research-on-novel-coronavirus-2019-ncov"
          target="_blank"
        >
          {strings.label("label_research_development")}
        </Link>
      </Row>
      <Row>
        <Link
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters"
          target="_blank"
        >
          {strings.label("label_mythbusters")}
        </Link>
      </Row>
    </>
  );
};

const Link = styled.a`
  flex: 1;
  text-decoration: none;
  padding: 8px 15px;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
  }
`;

export default WHOAdvice;
