import http from "./_http";
import { mapToContacts } from "./mappers";

class EmergencyServices {
  static async getContacts(countryCode) {
    const response = await http.get(`/api/v1/hotline/bycode/${countryCode}`);
    return mapToContacts(response.data);
  }
}

export default EmergencyServices;
