import React from "react";

const Loading = () => (
  <center>
    <div
      className="spinner-grow"
      role="status"
      style={{
        width: "3rem",
        height: "3rem"
      }}
    />
  </center>
);

export default Loading;
