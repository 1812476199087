import apisauce from 'apisauce';
import qs from 'qs';


let currentHost = 'https://be.cambodiacountry.com';

export const api = apisauce.create({
    baseURL: currentHost,
    // 60 seconds timeout...
    timeout: 60000
});

api.addRequestTransform((request) => {
    let {headers} = request.headers;
    if (headers) {
        headers["key"] = "Y292aWRfMTlfcmVwb3J0X21vYmlsZV9hcHA=";
    } else {
        headers = {"key": "Y292aWRfMTlfcmVwb3J0X21vYmlsZV9hcHA="}
    }
    request.headers = headers;

});

export default {
    get: async (url, params) => api.get(url, params),
    del: async url => api.delete(url, null),
    put: async (url, body, headers) => api.put(url, qs.stringify(body, {encode: true}), headers),
    putJson: async (url, body, headers) => api.put(url, JSON.stringify(body), headers),
    post: async (url, body, headers) => api.post(url, qs.stringify(body, {encode: true}), headers),
    postJson: async (url, body, headers) => api.post(url, JSON.stringify(body), headers),
    postImage: async (url, image, headers) => api.post(url, image, headers)
};
