import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
import withAppBody from "../../components/hoc/withAppBody";
import withResize from "../../components/hoc/withResize";
import routes from "../../routes";
import ViewPoints from "../../utils/view_points";
import Sidebar from "./sidebar";

const Body = ({ viewPoint }) => (
  <>
    <Sidebar />
    <CustomContainer
      className={viewPoint === ViewPoints.small ? "main no-padding" : "main"}
    >
      <Container fluid>
        <Suspense
          fallback={
            <div className="animated fadeIn pt-1 text-center">Loading...</div>
          }
        >
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
          </Switch>
        </Suspense>
      </Container>
    </CustomContainer>
  </>
);

const CustomContainer = styled.div`
  &.no-padding {
    &.main{
    }
    .container-fluid {
      padding: 0;
      margin: 0;
    }
  }
`;

export default withResize(withAppBody(Body));
