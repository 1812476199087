import React, { useState } from "react";
import { withRouter } from "react-router";
import "./App.scss";
import StringsContext from "./components/context/strings_context";
import Strings from "./utils/strings";
import * as firebase from "firebase/app";
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyCXf1eSlFiGaeGU0uYGLwjo_O1hTrGvNOY",
  authDomain: "covid-19-6538f.firebaseapp.com",
  databaseURL: "https://covid-19-6538f.firebaseio.com",
  projectId: "covid-19-6538f",
  storageBucket: "covid-19-6538f.appspot.com",
  messagingSenderId: "1025575218486",
  appId: "1:1025575218486:web:b3fa99b87ea4e1fd0b9797",
  measurementId: "G-SSDZW689CT"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const App = ({ children }) => {
  const [language, setLanguage] = useState("en");

  return (
    <StringsContext.Provider
      value={{ strings: new Strings(language), setLanguage, language }}
    >
      {children}
    </StringsContext.Provider>
  );
};

export default withRouter(App);
