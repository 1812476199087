import React, { useCallback, useEffect, useState } from "react";
import ViewPoints from '../../utils/view_points';

const withResize = Component => props => {
  const [viewPoint, setViewPoint] = useState(
    ViewPoints.check(window.innerWidth)
  );
  const onResize = useCallback(() => {
    setViewPoint(ViewPoints.check(window.innerWidth));
  }, [setViewPoint]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  Component.displayName = `withResize(${Component.name | Component.displayName})`;

  return <Component {...props} viewPoint={viewPoint} />;
};

export default withResize;
