import React, { useContext } from "react";
import styled from "styled-components";
import { Row } from "reactstrap";
import StringsContext from "../../../components/context/strings_context";

const CdcMohKh = () => {
  const { strings } = useContext(StringsContext);
  return (
    <>
      <Row>
        <Link href="http://cdcmoh.gov.kh" target="_blank">
          {strings.label("label_cdc_moh_website")}
        </Link>
      </Row>
    </>
  );
};

const Link = styled.a`
  flex: 1;
  text-decoration: none;
  padding: 8px 15px;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
  }
`;

export default CdcMohKh;
