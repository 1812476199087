import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { Row } from "reactstrap";
import StringsContext from "../../../components/context/strings_context";

const GuidanceMohKh = () => {
    const { strings } = useContext(StringsContext);
    const linkClick = useCallback(() => {
        document.getElementsByClassName("sidebar-fixed")[0].classList.remove("sidebar-show");
        window.location = "#/guidance";
    }, []);
    return (
        <>
            <Row>
                <Link onClick={linkClick}>
                    {strings.label("label_guidance")}
                </Link>
            </Row>
        </>
    );
};

const Link = styled.div`
  flex: 1;
  text-decoration: none;
  padding: 8px 15px;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
  }
`;

export default GuidanceMohKh;
