class ViewPoints {
  static small = "small";
  static medium = "medium";
  static large = "large";

  static check(width) {
    if (width < 600) {
      return this.small;
    } else if (width < 768) {
      return this.medium;
    } else {
      return this.large;
    }
  }
}

export default ViewPoints;
