function safePlus(one, two) {
  return (one | 0) + (two | 0);
}

function increase(stats, key, value) {
  stats[key] = safePlus(stats[key], value);
  return stats;
}

export function mapToGlobalStats(reports) {
  return mapToStats(reports, {
    totalKey: "countries",
    comparator: {
      compare(it) {
        return it.type && it.type.toLowerCase() === "country" ? 1 : 0;
      },
    },
  });
}

export function mapToLocalStats(reports) {
  return mapToStats(reports, {
    totalKey: "provinces",
    comparator: {
      compare(it) {
        return it.type && it.type.toLowerCase() === "province" ? 1: 0;
      },
    },
  });
}

export function mapToStats(reports, { totalKey, comparator }) {
  return reports
    .map((it) => it.virusCase)
    .reduce(
      (results, it) =>
        increase(
          increase(
            increase(
              increase(
                increase(results, "all", it.totalCase),
                "critical",
                it.totalSerious
              ),
              "deaths",
              it.totalDeath
            ),
            "recovered",
            it.totalRecovered
          ),
          totalKey,
          comparator.compare(it)
        ),
      { totalKey: 0 }
    );
}

export function mapToContacts(emergency) {
  return {
    countryCode: emergency?.countryCode,
    ambulance: [emergency?.ambulance],
    police: [emergency?.police],
    hotline: emergency?.covid,
  };
}
