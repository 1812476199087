import React, { Suspense } from "react";
import { AppSidebar } from "@coreui/react";

const withAppSidebar = Component => props => (
  <AppSidebar
    fixed
    display="lg"
    style={{ backgroundColor: "#607D8B", overflow: "auto" }}
  >
    <Suspense>
      <Component {...props} />
    </Suspense>
  </AppSidebar>
);

export default withAppSidebar;
