import { lazy } from "react";

const Details = lazy(() => import("./views/pages/details/cases_details"));
const Local = lazy(() => import("./views/pages/details/cases_details.km"));
const Home = lazy(() => import("./views/pages/home/home"));
const HomeKm = lazy(() => import("./views/pages/home/home.km"));
const AboutUs = lazy(() => import('./views/pages/about/about_us'));
const Guidance = lazy(() => import('./views/pages/guidance/guidance'))

const routes = [
    { path: "/", exact: true, name: "Home", component: Home },
    { path: "/km", exact: true, name: "Home", component: HomeKm },
    { path: "/details", exact: true, name: "Details", component: Details },
    { path: "/km/details", exact: true, name: "Details Local", component: Local },
    { path: "/about-us", exact: true, name: "About us", component: AboutUs },
    { path: "/guidance", exact: true, name: "Guidance", component: Guidance }
];

export default routes;
