import React, { useCallback } from "react";
import styled from "styled-components";
import GlobalFlag from "../assets/img/icon_global.png";
import KhmerFlag from "../assets/img/icon_km.png";
import { useLocation, useHistory } from "react-router";

const ContentSwitcher = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const onClick = useCallback(() => {
    push(pathname.indexOf("/km") > -1 ? "/" : "/km");
  }, [push, pathname]);

  return (
    <IconContainer onClick={onClick}>
      <Icon
        src={pathname.startsWith("/km") ? GlobalFlag : KhmerFlag}
        alt="icon-flag"
      />
    </IconContainer>
  );
};

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const IconContainer = styled.div`
  width: 48px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default ContentSwitcher;
