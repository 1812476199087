import en from "../i18n/en.json";
import km from "../i18n/km.json";

class Strings {
  constructor(code) {
    switch (code) {
      case "km":
        this.translations = km;
        break;
      case "en":
      default:
        this.translations = en;
        break;
    }
  }

  label(key) {
    return this.translations[key];
  }
}

export default Strings;
