import React, { useContext } from "react";
import { Row } from "reactstrap";
import styled from "styled-components";
import StringsContext from "../../../components/context/strings_context";

const EmergencyContact = ({ label, contact }) => (
  <Link href={`tel:${contact}`}>
    <ContactContainer>
      <span>{label}</span>
      <span>{contact}</span>
    </ContactContainer>
  </Link>
);

const ContactContainer = styled(Row)`
  justify-content: space-between;
  padding: 8px 15px;
  &:hover {
    text-decoration: none;
    background-color: white;
  }
`;

const EmergencyContacts = ({ contacts }) => {
  const { strings } = useContext(StringsContext);
  return (
    <>
      {contacts.ambulance?.map(it => (
        <EmergencyContact
          key={`am-${it}`}
          label={strings.label("label_ambulance")}
          contact={it}
        />
      ))}
      {contacts.police?.map(it => (
        <EmergencyContact
          key={`po-${it}`}
          label={strings.label("label_police")}
          contact={it}
        />
      ))}
      {contacts.hotline?.map(it => (
        <EmergencyContact
          key={`hot-${it}`}
          label={strings.label("label_hotline")}
          contact={it}
        />
      ))}
    </>
  );
};

const Link = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    background-color: white;
  }
`;

export default EmergencyContacts;
