import React from "react";

const withAppBody = Component => props => {
  Component.displayName = `withAppBody(${Component.name |
    Component.displayName})`;

  return (
    <div className="app-body">
      <Component {...props} />
    </div>
  );
};

export default withAppBody;
