import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import CountryContext from "../../components/context/country_context";
import StringsContext from "../../components/context/strings_context";
import withAppSidebar from "../../components/hoc/withAppSidebar";
import EmergencyServices from "../../services/emergency_services";
import CdcMohKh from "../pages/sidebar/cdc_moh_kh";
import EmergencyContacts from "../pages/sidebar/emergency_contacts";
import WHOAdvice from "../pages/sidebar/who_advice";
import GuidanceMohKh from "../pages/sidebar/guidance_moh_kh";

const Sidebar = () => {
  const [contacts, setContacts] = useState({
    police: [],
    ambulance: [],
    hotline: []
  });
  const { strings, setLanguage } = useContext(StringsContext);
  const countryCode = useContext(CountryContext);

  useEffect(() => {
    (async () => {
      const contacts = await EmergencyServices.getContacts(countryCode);
      setContacts(contacts);
      if (countryCode === "KH") {
        setLanguage("km");
      }
    })();
  }, [setLanguage, countryCode]);

  return (
    <Col style={{ paddingBottom: 55 }}>
      <TitleContainer>
        <Title>{strings.label("label_need_help")}</Title>
      </TitleContainer>
      <EmergencyContacts contacts={contacts} />
      {countryCode === "KH" && (
        <>
          <hr />
          <TitleContainer>
            <Title>{strings.label("label_moh")}</Title>
          </TitleContainer>
          <CdcMohKh />
          <GuidanceMohKh />
        </>
      )}
      <hr />
      <TitleContainer>
        <Title>{strings.label("label_who_advice")}</Title>
      </TitleContainer>
      <WHOAdvice />
    </Col>
  );
};

const TitleContainer = styled(Row)`
  margin: 8px 0px;
`;

const Title = styled.h5`
  font-weight: bold;
`;

export default withAppSidebar(Sidebar);
