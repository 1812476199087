import React from "react";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { HashRouter, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import Main from "./views/containers/main";

ReactDOM.render(
  <HashRouter>
    <App>
      <Route path="/" name="Home" render={props => <Main {...props} />} />
    </App>
  </HashRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
