import { AppHeader } from "@coreui/react";
import React from "react";
import styled from "styled-components";

const withAppHeader = Component => props => {
  Component.displayName = `withHeader(${Component.name |
    Component.displayName})`;

  return (
    <AppHeader fixed className="text-center">
      <HeaderContainer>
        <Component {...props} />
      </HeaderContainer>
    </AppHeader>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  background-color: #607d8b;
`;

export default withAppHeader;
