import { AppSidebarToggler } from "@coreui/react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import styled from "styled-components";
import CountryContext from "../../components/context/country_context";
import StringsContext from "../../components/context/strings_context";
import withAppHeader from "../../components/hoc/withAppHeader";
import withStrings from "../../components/hoc/withStrings";

const Header = ({ renderSwitcher }) => {
  const { strings } = useContext(StringsContext);
  const countryCode = useContext(CountryContext);
  return (
    <>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppSidebarToggler
        className="d-md-down-none"
        display="lg"
        header
      />
      <HeaderContainer>
        <StyledLink to={countryCode === 'KH' ? "/km" : "/"}>
          <Title>{strings.label("label_covid_19_updates")}</Title>
        </StyledLink>
        {renderSwitcher}
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled(Row)`
  display: flex;
  flex: 1;
  padding-right: 16px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: white;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex: auto;
  justify-content: center;
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default withAppHeader(withStrings(Header));
